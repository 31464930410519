<template>
  <div>
    <div v-if="!isSucc" class="page flex_direction_column">
      <div class="title">请选择注册类型</div>
      <div class="nav flex">
        <div class="nav-item flex" :class="{ active: ruleForm.type == 1 }" @click="ruleForm.type = 1">
          <img v-if="ruleForm.type != 1" src="../../../assets/image/img/50.png" />
          <img v-else src="../../../assets/image/img/47.png" />
          公司注册
        </div>
        <div class="nav-item flex" :class="{ active: ruleForm.type == 2 }" @click="ruleForm.type = 2">
          <img v-if="ruleForm.type != 2" src="../../../assets/image/img/48.png" />
          <img v-else src="../../../assets/image/img/49.png" />
          组织注册
        </div>
      </div>
      <div class="form flex_direction_column">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item :label="ruleForm.type == 1 ? '公司名称' : '组织名称'" prop="cation_name">
            <el-input
              :placeholder="ruleForm.type == 1 ? '请输入公司名称' : '请输入组织名称'"
              v-model="ruleForm.cation_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="组织方简介" prop="name">
            <el-input placeholder="请输入组织方简介" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="法人姓名" prop="legal">
            <el-input placeholder="请输入法人姓名" v-model="ruleForm.legal"></el-input>
          </el-form-item>
          <el-form-item label="法人电话" prop="legal_phone">
            <el-input placeholder="请输入法人电话" v-model="ruleForm.legal_phone"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="contacts">
            <el-input placeholder="请输入联系人姓名" v-model="ruleForm.contacts"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="legal_phone">
            <el-input placeholder="请输入联系人电话" v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input show-password placeholder="请输入密码" v-model="ruleForm.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="right_password">
            <el-input show-password placeholder="请再次输入密码" v-model="ruleForm.right_password"></el-input>
          </el-form-item>
          <!-- <el-form-item label="验证码" prop="code">
            <el-input placeholder="请输入验证码" v-model="ruleForm.code"></el-input>
            <div class="send" :class="{ sendActive: isSend }" @click="sendCode">
              {{ isSend ? '短信已发送' : '发送验证码' }}
            </div>
          </el-form-item> -->
          <el-form-item :label="ruleForm.type == 1 ? '营业执照' : '组织机构代码证'" prop="card_img">
            <div class="flex_wrap">
              <div class="oss-upload">
                <img class="card" v-if="ruleForm.card_img" :src="ruleForm.card_img" alt="" />
                <i v-else class="el-icon-plus"></i>
                <input type="file" class="upload-img-file" @change="ossUploadImg($event, 1)" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="上传授权书" prop="empower_img">
            <div class="flex_wrap">
							<div class="change_img" v-for="(item,index) in ruleForm.empower_img" :key="index" style="display:flex;">
								<img class="card" v-if="item" :src="item" alt="" />
								<div class="icon_delete" @click="deleteClick(index) ">
									<i class="el-icon-delete"></i>
								</div>
							</div>
              <div class="oss-upload">
                <i class="el-icon-plus"></i>
                <input type="file" class="upload-img-file" @change="ossUploadImg($event, 2)" />
              </div>
            </div>
						<a href="/static/网站授权书.docx">下载授权书模板</a>
          </el-form-item>
        </el-form>
        <div class="read flex">
          <div class="check" @click="isCheck = !isCheck">
            <img v-if="isCheck" src="../../../assets/image/icon/13.png" alt="" />
          </div>
          <p class="flex" @click="isClause = !isClause">《江苏省田径赛事平台服务协议书》</p>
        </div>
        <div class="btn" @click="saveClick">确认</div>
      </div>
    </div>
    <div v-if="isSucc" class="page pages flex_direction_column">
      <span>您已成功提交注册资料</span>
      <span>我们会在3-5个工作日内审核，请耐心等待</span>
      <p>感谢您使用江苏马拉松赛事管理系统，我们会尽快审核。如有疑问请致电官方咨询电话：400-9898-998</p>
    </div>
    <ClausePopup
      title="注册条款"
      :html="$store.state.config.agreement"
      :isClause="isClause"
      @close="closeClause"
    ></ClausePopup>
  </div>
</template>

<script>
import ClausePopup from '@/components/popup/clause.vue'
import { ossUpload } from '@/utils/tools'
import { getMobileCode, regClub } from '@/service/api/user.js'
import { phoneReg } from '@/utils/regular.js'
const validatePhone = (rule, value, callback) => {
  phoneReg(value)
    .then(() => {
      callback()
    })
    .catch((err) => {
      return callback(new Error(err))
    })
}
export default {
  components: { ClausePopup },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value == this.ruleForm.password) {
        callback()
      } else {
        callback(new Error('两次密码不相同'))
      }
    }
    return {
      isClause: false,
      isSucc: false,
      isSend: false,
      isCheck: false,
      ruleForm: {
        type: 1,
        cation_name: '',
        name: '',
        legal: '',
        legal_phone: '',
        contacts: '',
        phone: '',
        password: '',
        right_password: '',
        // code: '',
        card_img: '',
        empower_img: []
      },
      rules: {
        name: [{ required: true, message: '请输入组织方名称', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        mailbox: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        cation_name: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        card_img: [{ required: true, message: '请上传图片', trigger: 'change' }],
        empower_img: [{ required: true, message: '请上传图片', trigger: 'change' }],
        legal_phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],

        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        right_password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        legal: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }]
      },
      codeTime: 60
    }
  },

  mounted() {},

  methods: {
    async regClub() {
      const res = await regClub(this.ruleForm)
      if (res.code == 1) {
        // this.isSucc = true
        this.$message.success(res.msg)
        setTimeout(() => {
          window.open(process.env.VUE_APP_BASE_URL + '/seller/')
        }, 500)
      } else {
        this.$message.warning(res.msg)
      }
    },
    // oss上传
    ossUploadImg(e, type) {
      console.log(e, type)
      let timestamp = new Date().getTime()
      var file = e.currentTarget.files[0]
      let fileType = file.name.substring(file.name.lastIndexOf('.')) || '.jpg'
      ossUpload('pc/' + `${timestamp}${fileType}`, file).then((res) => {
        if (type == 1) {
          this.ruleForm.card_img = res
        } else {
          this.ruleForm.empower_img.push(res)
        }
      })
    },
		//删除数组照片
		deleteClick(index){
			this.ruleForm.empower_img.splice(index,1)
		},
    // 获取验证码
    async getMobileCode() {
      const res = await getMobileCode({
        mobile: this.ruleForm.phone
      })
      if (res.code == 1) {
        var timer = null
        this.isSend = !this.isSend
        timer = setInterval(() => {
          this.codeTime = this.codeTime - 1
          if (this.codeTime == 0) {
            this.codeTime = 60
            this.isSend = !this.isSend
            clearInterval(timer)
          }
        }, 1000)
      } else {
        this.$message.error(res.msg)
      }
    },
    sendCode() {
      if (this.ruleForm.phone == '') return this.$message.warning('请填写手机号')
      phoneReg(this.ruleForm.phone).then(() => {
        // var time = 60
        // var timer = null
        if (!this.isSend) {
          // 获取验证码
          this.getMobileCode()
          // this.isSend = !this.isSend
        } else {
          this.$message.warning(`${this.codeTime}后可重新发送`)
        }
      })
    },
    saveClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.isCheck) return this.$message.warning('请勾选协议')
          this.regClub()
        } else {
          return false
        }
      })
    },
    closeClause() {
      this.isClause = false
    }
  },
  watch: {
    'ruleForm.type'() {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../css/common.less');
/deep/ .el-form-item__label {
  display: flex;
}
/deep/ .el-form-item__label:before {
  display: block;
}
/deep/ .el-form {
  width: 700px;
}
.read {
  .check {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: 1px solid #eee;
    img {
      width: 24px;
      height: 24px;
    }
  }
  p {
    cursor: pointer;
    color: #2e64ce;
    font-size: 16px;
    margin-left: 10px;
    &::before {
      content: '我已阅读并同意';
      color: #000;
    }
  }
}
.btn {
  width: 220px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin-top: 50px;
  color: #fff;
  font-size: 20px;
  background: rgba(230, 66, 94);
}
.change_img{
	width: 148px;
	height: 148px;
	margin-bottom: 20px;
	margin-right: 20px;
	position: relative;
	font-size: 20px;
	.card {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}
	&:hover {
		border-color: #409eff;
	}
	.icon_delete{
		position: absolute;
		top: -10px;
		right: 0px;
	}
	img {
		width: 100%;
		height: 100%;
		margin-right: 10px;
		object-fit: cover;
		border-radius: 6px;
		&:last-child {
			margin-right: 0;
		}
	}
}
.oss-upload {
  width: 148px;
  height: 148px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
  line-height: 146px;
  border-radius: 6px;
  font-size: 28px;
  color: #8c939d;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  position: relative;
  margin-right: 10px;
  .card {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  &:hover {
    border-color: #409eff;
  }
  img {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
  .upload-img-file {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    cursor: pointer;
    position: absolute;
  }
}
.active {
  color: #d50018;
  border: 1px solid #d50018 !important;
}
.page {
  background-color: #fff;
  align-items: center;
  justify-content: center;
  width: 1360px;
  margin: 0 auto 68px auto;
  background-image: none;
  .title {
    width: 1360px;
    height: 42px;
    font-size: 30px;
    padding-top: 62px;
    padding-bottom: 55px;
    // font-weight: bold;
    text-align: center;
    line-height: 30px;
  }
  .nav {
    justify-content: center;
    // text-align: center;
    width: 1360px;
    height: 80px;
    line-height: 80px;
    // background-color: #fcfcfc;
    .nav-item {
      padding: 0 44px;
      border: 1px solid #dddddd;

      img {
        width: 36px;
        height: 38px;
        margin-right: 12px;
      }
      cursor: pointer;
      margin-right: 105px;
      font-size: 24px;
      // font-weight: bold;
    }
  }
}
.form {
  // margin-top: 80px;
  width: 650px;
  .agre {
    margin-top: 51px;
    .check {
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #ddd;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .desc {
      line-height: 20px;
      font-size: @font_size_14;
      span {
        height: 20px;
        margin-left: 10px;
      }
      p {
        cursor: pointer;
        color: #2e64ce;
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
}
.pages {
  padding: 200px 0;
  span {
    height: 48px;
    font-size: 34px;
    font-weight: bold;
    line-height: 48px;
    color: #333333;

    &:nth-child(2) {
      margin-top: 24px;
    }
  }
  p {
    width: 836px;
    height: 78px;
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    color: #777777;
    margin-top: 26px;
  }
}
</style>
